import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  expansionPanel: {
    background: 'transparent',
    padding: 0,
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  expansionPanelSummary: {
    padding: 0,
    '&.Mui-expanded': {
      minHeight: 'auto',
      '& div:first-of-type': {
        margin: '12px 0',
      },
    },
  },
  ExpansionPanelDetails: {
    padding: 0,
    flexDirection: 'column',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    padding: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    color: theme.palette.text.green,
  },
}));

const GreenCheckbox = withStyles(theme => ({
  root: {
    color: theme.palette.text.green,
    '&$checked': {
      color: theme.palette.text.green,
    },
  },
  checked: {},
}))(props => <Checkbox color="default" {...props} />);

function Filters(props) {
  const { locations, departments } = props;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  function setLocation(event) {
    props.setLocation(event.target.checked, event.target.value);
  }
  function setDepartment(event) {
    props.setDepartment(event.target.checked, event.target.value);
  }
  return (
    <div className={classes.root}>
      <ExpansionPanel
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
        className={classes.expansionPanel}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon className={classes.icon} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className={classes.expansionPanelSummary}
        >
          <Typography className={classes.heading}>LOCATION</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.ExpansionPanelDetails}>
          {locations.map((item, index) => (
            <FormControlLabel
              key={index}
              control={
                <GreenCheckbox onChange={setLocation} value={item.node.gh_Id} />
              }
              label={item.node.name}
            />
          ))}
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}
        className={classes.expansionPanel}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon className={classes.icon} />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
          className={classes.expansionPanelSummary}
        >
          <Typography className={classes.heading}>DEPARTMENT</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.ExpansionPanelDetails}>
          {departments.map((item, index) => {
            if (item.node.jobs) {
              return (
                <FormControlLabel
                  key={index}
                  control={
                    <GreenCheckbox
                      onChange={setDepartment}
                      value={item.node.gh_Id}
                    />
                  }
                  label={item.node.name.trim()}
                />
              );
            }
          })}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}

Filters.propTypes = {
  data: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  departments: PropTypes.array.isRequired,
  setLocation: PropTypes.func,
  setDepartment: PropTypes.func,
};

export default Filters;
