import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';

const FilterNameOffice = data => {
  var value = null;
  data.data.allGreenhouseOffice.edges.map(item => {
    if (item.node.gh_Id == data.id) {
      value = item.node.name;
    }
  });
  if (value) {
    return (
      <Box display="flex" alignItems="center">
        <FontAwesomeIcon icon={faCheckSquare} color="#b9bdbf" />
        <Box ml={0.5}>
          <Typography variant="body1">{value}</Typography>
        </Box>
      </Box>
    );
  }
  return null;
};

const FilterNameDepartment = data => {
  var value = null;
  data.data.allGreenhouseDepartment.edges.map(item => {
    if (item.node.gh_Id == data.id) {
      value = item.node.name;
    }
  });
  if (value) {
    return (
      <Box display="flex" alignItems="center">
        <FontAwesomeIcon icon={faCheckSquare} color="#b9bdbf" />
        <Box ml={0.5}>
          <Typography variant="body1">{value}</Typography>
        </Box>
      </Box>
    );
  }
  return null;
};

function Selected(props) {
  const { location, department, data } = props;
  return (
    <Box mt={[0, 0, 2]}>
      {location.length > 0 && (
        <React.Fragment>
          {location.map((item, index) => (
            <React.Fragment key={index}>
              <FilterNameOffice id={item} data={data} />
            </React.Fragment>
          ))}
        </React.Fragment>
      )}
      {department.length > 0 && (
        <React.Fragment>
          {department.map((item, index) => (
            <React.Fragment key={index}>
              <FilterNameDepartment id={item} data={data} />
            </React.Fragment>
          ))}
        </React.Fragment>
      )}
    </Box>
  );
}

Selected.propTypes = {
  location: PropTypes.array,
  department: PropTypes.array,
  data: PropTypes.object.isRequired,
};

export default Selected;
