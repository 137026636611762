import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import getJobPostPathname from 'helpers/getJobPostPathname';
import Filters from 'components/filters';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import withWidth from '@material-ui/core/withWidth';
import { compose, isMobileView, isTabletView } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faFilter } from '@fortawesome/free-solid-svg-icons';
import BtnSml from 'components/buttons/btnsml';
import Selected from 'components/filters/selected';
import palette from 'styles/palette';
import BtnLg from 'components/buttons/btnlg';
import Alert from '@material-ui/lab/Alert';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import axios from 'axios';

import CircularProgress from '@material-ui/core/CircularProgress';

import ReactTagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css'; // If you want the default styling
import './CustomTagsInput.css';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 150,
  },
  TableCell: {
    borderBottom: '0px',
    paddingBottom: '10px',
  },
  TableCellFirstRow: {
    borderBottom: '0px',
    paddingBottom: '10px',
    paddingTop: '30px',
  },
  paginationRoot: {
    borderBottom: 0,
  },
  paginationToolbar: {
    paddingTop: '10px',
    borderTop: `1px solid ${theme.palette.text.input}`,
    marginTop: '25px',
    paddingLeft: '0px',
    justifyContent: 'center',
  },
  paginationSpacer: {
    flex: 0,
  },
  paginationCaption: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  paginationInput: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  LeaderCell: {
    paddingBottom: '10px',
    paddingLeft: '0px',
    borderBottom: '0px',
  },
  LeaderCellFirstRow: {
    paddingTop: '30px',
    paddingBottom: '10px',
    paddingLeft: '0px',
    borderBottom: '0px',
  },
  LeaderCellHead: {
    paddingLeft: '0px',
    width: '40%',
  },
  SecondCellHead: {
    width: '25%',
  },
  ThirdCellHead: {
    width: '25%',
  },
  LastCellHead: {
    width: '10%',
  },
  ActionCell: {
    borderBottom: '0px',
    paddingRight: '0px',
    paddingBottom: '10px',
  },
  ActionCellFirstRow: {
    borderBottom: '0px',
    paddingRight: '0px',
    paddingBottom: '10px',
    paddingTop: '30px',
  },
  Link: {
    borderBottom: `2px solid ${palette.text.green}`,
    transition: 'all 0.2s ease-in-out',
    color: palette.text.green,
    fontWeight: '800',
    '&:hover': {
      color: palette.text.green,
      borderBottom: '2px solid transparent',
    },
  },
  upperCase: {
    textTransform: 'uppercase',
  },
  textField: {
    backgroundColor: theme.palette.background.lgrey,
    marginTop: theme.spacing(0.5),
  },
  outlinedRoot: {
    '&:hover $notchedOutline': {
      borderColor: theme.palette.text.primary,
    },
    '&$focused $notchedOutline': {
      borderColor: theme.palette.text.primary,
      borderWidth: 1,
    },
  },
  input: {
    padding: theme.spacing(0.9),
  },
  notchedOutline: {
    borderRadius: '0',
  },
  focused: {},
  icon: {
    color: theme.palette.background.green,
  },
  highlight: {
    color: theme.palette.text.green,
    fontStyle: 'italic',
    fontWeight: '800',
  },
  filtersReveal: {
    [theme.breakpoints.down('sm')]: {
      margin: '30px 0px',
      maxHeight: '0px',
      overflow: 'hidden',
      transition: 'all 0.2s ease-in-out',
    },
  },
  filterBtn: {
    border: `1px solid ${theme.palette.text.input}`,
    borderRadius: '0px',
  },
  filterIcon: {
    color: theme.palette.text.green,
    marginLeft: '10px',
  },
  externalLink: {
    transition: 'color 0.2s ease-in-out',
    '&:hover': {
      color: '#4186bd',
    },
  },
  alertBox: {
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0 0 10px 0 rgba(67,134,179,.15)',
    '-webkit-box-shadow': '0 0 10px 0 rgba(67,134,179,.15)',
    '& svg': {
      color: '#0b9829',
    },
  },
  greenTitle: {
    color: theme.palette.text.green,
  },
  greyBg: {
    backgroundColor: '#F9F6F3',
  },
}));

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const JobListing = props => {
  const {
    data,
    width,
    location,
    tableLite,
    ghId,
    latestJobs,
    jobData,
    filtersOff,
    departmentTitle,
    showMoreButton,
    departments,
  } = props;
  const classes = useStyles();
  const [search, setSearch] = useState(
    location && location.state && location.state.searchTerm
      ? location.state.searchTerm
      : ''
  );
  const [locationfilter, setLocationfilter] = useState([]);
  const [department, setDepartment] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [expanded, setExpanded] = useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [tags, setTags] = useState([]);

  const [departmentAlert, setDepartmentAlert] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  function handleExpandFilter() {
    setExpanded(!expanded);
  }
  function handleChangePage(event, newPage) {
    setPage(newPage);
  }
  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }
  function updateLocation(checked, newValue) {
    setPage(0);
    if (!checked) {
      var index = locationfilter.indexOf(parseInt(newValue));
      if (index > -1) {
        locationfilter.splice(index, 1);
        setLocationfilter([...locationfilter]);
      }
    } else {
      setLocationfilter([...locationfilter, parseInt(newValue)]);
    }
  }
  function updateDepartment(checked, newValue) {
    setPage(0);
    if (!checked) {
      var index = department.indexOf(parseInt(newValue));
      if (index > -1) {
        department.splice(index, 1);
        setDepartment([...department]);
      }
    } else {
      setDepartment([...department, parseInt(newValue)]);
    }
  }
  function filterTags(data) {
    if (data) {
      if (data.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
        return true;
      }
    }
  }
  function updateSearch(e) {
    setSearch(e.target.value);
    setPage(0);
  }

  var list = [];

  if (latestJobs && jobData) {
    list = jobData.edges.filter((item, index) => {
      return true;
    });
  } else {
    list = data.allGreenhouseJob.edges.filter((item, index) => {
      var includeItem = true;
      item.node.title.toLowerCase().indexOf(search.toLowerCase()) !== -1
        ? null
        : (includeItem = false);

      if (item.node.location && item.node.location.name) {
        item.node.location.name.toLowerCase().indexOf(search.toLowerCase()) !==
        -1
          ? (includeItem = true)
          : null;
      }

      if (item.node.offices) {
        item.node.offices.map(item => {
          item.name
            .toLowerCase()
            .replace(/[^A-Z0-9]/gi, '')
            .indexOf(search.toLowerCase().replace(/[^A-Z0-9]/gi, '')) !== -1
            ? (includeItem = true)
            : null;
        });
      }

      const filterRes = filterTags(item.node.fields.jobtags);
      if (filterRes === true) {
        includeItem = true;
      }
      if (locationfilter.length > 0) {
        var count = 0;
        item.node.offices.map(item => {
          locationfilter.includes(item.gh_Id) ? count++ : null;
        });
        count == 0 ? (includeItem = false) : null;
      }
      if (department.length > 0 && item.node.departments) {
        department.includes(item.node.departments[0].gh_Id)
          ? null
          : (includeItem = false);
      }
      if (ghId) {
        if (item.node.departments && item.node.departments[0].gh_Id != ghId) {
          includeItem = false;
        }
      }
      if (!item.node.departments) {
        includeItem = false;
      }

      if (latestJobs && index > 10) {
        includeItem = false;
      }

      return includeItem;
    });
  }

  list = list.sort((a, b) =>
    a.node.title.trimStart().localeCompare(b.node.title.trimStart())
  );

  const handleSubmit = e => {
    e.preventDefault();

    setSubmitting(true);

    const formData = new FormData();
    formData.append('Name', name);
    formData.append('Email', email);
    formData.append('Department', departmentAlert);
    formData.append('Job Titles', tags.join(', ')); // Assuming you want to send tags as comma-separated string

    formData.append(
      'Message',
      name +
        ' would like to hear about new jobs in the ' +
        departmentAlert +
        ' department and with Job titles such as ' +
        tags.join(', ') +
        '. Thier email address is ' +
        email
    );

    // Replace 'YOUR_FORMSPREE_ENDPOINT' with your endpoint URL
    axios
      .post('https://formspree.io/f/xleypplr', formData)
      .then(response => {
        if (response.status === 200) {
          setSubmitted(true);
          // Reset the form or redirect the user or any other post-submit action
        } else {
          alert('Error submitting form.');
        }
        setSubmitting(false);
      })
      .catch(error => {
        setSubmitting(false);
        alert('There was an error submitting the form.');
      });
  };

  // turn on/off hiring update
  const showHireUpdate = false;

  useEffect(() => {
    // If the state was passed and openModal is true
    if (location?.state?.openModal) {
      setOpenModal(true);
    }
  }, [location]);

  return (
    <Box
      component="div"
      pt={tableLite ? 0 : [2, 4]}
      pb={4}
      className={!departments ? classes.greyBg : null}
    >
      <Container>
        <Grid container justify="space-between">
          {tableLite && list.length == 0 && (
            <Grid item xs={12}>
              <Typography align="center" variant="h5">
                Sorry there are no opportunities at the moment please check back
                soon or explore all opportunities
              </Typography>
            </Grid>
          )}
          {!tableLite && showHireUpdate && (
            <Grid item xs={12}>
              <Box mb={4}>
                <Alert severity="info" className={classes.alertBox}>
                  <Typography variant="h5" component="p">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://tech.ovoenergy.com/covid19-hiring-update-from-the-talent-acquisition/"
                      className={classes.externalLink}
                    >
                      Hiring update from the Talent Acquisition Team
                    </a>
                  </Typography>
                </Alert>
              </Box>
            </Grid>
          )}
          {!tableLite && (
            <Grid item xs={12} md={3}>
              <Grid container justify="space-between" alignItems="center">
                <Grid item>
                  <Box mt={[0, 0, 2]} mb={[0, 0, 2]}>
                    <Typography
                      variant="h4"
                      component="p"
                      style={{ fontWeight: 900, color: '#00261C' }}
                    >
                      Our {latestJobs && ' latest'} roles
                    </Typography>
                    <Typography
                      component="span"
                      variant="h1"
                      style={{ fontSize: '4rem' }}
                    >
                      {list.length < 10 ? `0${list.length}` : list.length}
                    </Typography>{' '}
                    {departmentTitle && (
                      <Typography
                        variant="h4"
                        component="p"
                        style={{ fontWeight: 900 }}
                      >
                        in {departmentTitle}
                      </Typography>
                    )}
                    {!filtersOff && (
                      <Selected
                        location={locationfilter}
                        department={department}
                        data={data}
                      />
                    )}
                  </Box>
                </Grid>

                {isTabletView(width) && !filtersOff && (
                  <Grid item>
                    <Button
                      onClick={handleExpandFilter}
                      className={classes.filterBtn}
                      aria-label="Open job filters"
                    >
                      <Typography component="span" variant="body2">
                        Filters
                      </Typography>
                      <FontAwesomeIcon
                        icon={faFilter}
                        className={classes.filterIcon}
                      />
                    </Button>
                  </Grid>
                )}
              </Grid>

              {!filtersOff && (
                <Box
                  className={classes.filtersReveal}
                  style={expanded === true ? { maxHeight: '500px' } : {}}
                >
                  <Box mb={2}>
                    <Typography
                      variant="subtitle1"
                      component="p"
                      className={classes.upperCase}
                    >
                      Job Search
                    </Typography>
                    <OutlinedInput
                      className={classes.textField}
                      variant="outlined"
                      fullWidth
                      classes={{
                        root: classes.outlinedRoot,
                        notchedOutline: classes.notchedOutline,
                        focused: classes.focused,
                        input: classes.input,
                      }}
                      value={search}
                      onChange={updateSearch}
                      endAdornment={
                        <InputAdornment position="end" className={classes.icon}>
                          <FontAwesomeIcon icon={faSearch} />
                        </InputAdornment>
                      }
                    />
                  </Box>
                  <Box>
                    <Filters
                      setLocation={updateLocation}
                      setDepartment={updateDepartment}
                      data={data.allGreenhouseJob.edges}
                      locations={data.allGreenhouseOffice.edges}
                      departments={data.allGreenhouseDepartment.edges}
                    />
                  </Box>
                </Box>
              )}
              {openModal && (
                <div
                  style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Opaque background
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backdropFilter: 'blur(4px)', // Blur out background
                    zIndex: 100,
                  }}
                >
                  <div
                    style={{
                      width: '60%',
                      height: '60%',
                      backgroundColor: 'white',
                      position: 'relative',
                      padding: '20px',
                      borderRadius: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      onClick={handleCloseModal}
                      style={{
                        position: 'absolute',
                        right: '-50px',
                        top: '-50px',
                        border: '0',
                        background: 'transparent',
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCircleXmark}
                        style={{
                          fontSize: '2.5rem',
                          color: '#fff',
                          cursor: 'pointer',
                        }}
                      />
                    </button>
                    {!submitted && (
                      <Box>
                        <Typography
                          variant="h3"
                          style={{ textAlign: 'center' }}
                        >
                          Cant find your perfect role?
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ textAlign: 'center', margin: '20px 0' }}
                        >
                          Enter your email and area of expertise below to sign
                          up for job alerts and we will get in contact if
                          anything comes up
                        </Typography>
                        <Box position="relative">
                          {submitting && (
                            <Box
                              position="absolute"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              style={{
                                height: '100%',
                                width: '100%',
                                backgroundColor: 'rgb(255 255 255 / 50%)',
                                backdropFilter: 'blur(1px)',
                              }}
                            >
                              <CircularProgress />
                            </Box>
                          )}

                          <form
                            onSubmit={handleSubmit}
                            style={{ width: '100%' }}
                          >
                            <input
                              type="text"
                              required
                              placeholder="Name"
                              value={name}
                              onChange={e => setName(e.target.value)}
                              style={{
                                width: '100%',
                                padding: '10px',
                                marginBottom: '10px',
                                border: '1px solid #ebe7e3',
                                borderRadius: '2px',
                                color: '#00261c',
                              }}
                            />
                            <input
                              type="email"
                              required
                              placeholder="Enter email"
                              value={email}
                              onChange={e => setEmail(e.target.value)}
                              style={{
                                width: '100%',
                                padding: '10px',
                                marginBottom: '10px',
                                border: '1px solid #ebe7e3',
                                borderRadius: '2px',
                                color: '#00261c',
                              }}
                            />
                            <select
                              required
                              value={departmentAlert}
                              onChange={e => setDepartmentAlert(e.target.value)}
                              onBlur={e => setDepartmentAlert(e.target.value)}
                              style={{
                                width: '100%',
                                padding: '10px',
                                marginBottom: '10px',
                                border: '1px solid #ebe7e3',
                                borderRadius: '2px',
                                color: '#00261c',
                              }}
                            >
                              <option>My Department</option>
                              {data.allGreenhouseDepartment?.edges.map(
                                (item, key) => {
                                  return (
                                    <option key={key} value={item.node?.name}>
                                      {item.node?.name}
                                    </option>
                                  );
                                }
                              )}
                            </select>
                            <ReactTagsInput
                              value={tags} // state for holding tags
                              onChange={newTags => setTags(newTags)}
                              inputProps={{
                                placeholder:
                                  'Add a job title tag and press enter',
                              }}
                              className="react-tagsinput" // Default styling, you can modify as required
                            />
                            <div
                              style={{
                                margin: '10px 0',
                                textAlign: 'left',
                                width: '100%',
                              }}
                            >
                              <input
                                type="checkbox"
                                required
                                id="gdpr-consent"
                                style={{
                                  marginRight: '10px',
                                }}
                              />
                              <label
                                htmlFor="gdpr-consent"
                                style={{ fontSize: '14px', color: '#00261c' }}
                              >
                                I agree to allow OVO to store my email address
                                and contact me about future job roles. I
                                understand that my data will be used in
                                accordance with OVOs{' '}
                                <a
                                  href="/privacy-policy"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    color: '#00261c',
                                    textDecoration: 'underline',
                                  }}
                                >
                                  privacy policy
                                </a>
                                , and I have the right to withdraw this consent
                                at any time.
                              </label>
                            </div>
                            <Box mt={2}>
                              <button
                                type="submit"
                                style={{
                                  padding: '10px 20px',
                                  border: 'none',
                                  borderRadius: '2px',
                                  backgroundColor: '#00261c',
                                  color: '#ffffff',
                                  cursor: 'pointer',
                                }}
                              >
                                Submit
                              </button>
                            </Box>
                          </form>
                        </Box>
                      </Box>
                    )}

                    {submitted && (
                      <Box>
                        <Typography variant="h3" component="p" align="center">
                          Thanks {name}! We will keep you informed of any roles
                          that might be suitable!
                        </Typography>
                      </Box>
                    )}
                  </div>
                </div>
              )}
            </Grid>
          )}
          {list.length > 0 && (
            <Grid item xs={12} md={!tableLite ? 8 : 12}>
              <Table className={classes.table} aria-label="Jobs Table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.LeaderCellHead}>
                      <Typography variant="subtitle1" component="p">
                        ROLE
                      </Typography>
                    </TableCell>
                    {!isMobileView(width) && (
                      <React.Fragment>
                        <TableCell className={classes.SecondCellHead}>
                          <Typography variant="subtitle1" component="p">
                            DEPARTMENT
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.ThirdCellHead}>
                          <Typography variant="subtitle1" component="p">
                            LOCATION
                          </Typography>
                        </TableCell>
                      </React.Fragment>
                    )}
                    <TableCell className={classes.LastCellHead}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list.length == 0 && !tableLite && (
                    <TableRow>
                      <TableCell
                        scope="row"
                        colSpan={3}
                        className={classes.LeaderCellFirstRow}
                      >
                        <Typography variant="h4" component="p" align="center">
                          Sorry we couldnt find any matches
                          {search.length > 0 && (
                            <React.Fragment>
                              {' '}
                              for{' '}
                              <Typography
                                className={classes.highlight}
                                variant="h4"
                                component="span"
                              >
                                &quot;{search}&quot;
                              </Typography>
                            </React.Fragment>
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {(rowsPerPage > 0
                    ? list.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : list
                  ).map((item, index) => (
                    <TableRow key={index}>
                      <TableCell
                        scope="row"
                        className={
                          index == 0
                            ? classes.LeaderCellFirstRow
                            : classes.LeaderCell
                        }
                      >
                        <Typography>
                          <Link
                            to={`/${getJobPostPathname(item.node)}`}
                            className={classes.Link}
                          >
                            {item.node.title}
                          </Link>
                          {isMobileView(width) && (
                            <Box pt={0.5}>
                              <Grid container>
                                <Grid item>
                                  <Box mr={0.5}>
                                    <Typography variant="body2">
                                      {item.node.location.name}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item>
                                  {item.node.departments && (
                                    <React.Fragment>
                                      {item.node.departments.map(
                                        (item, index) => (
                                          <Typography
                                            key={index}
                                            variant="body2"
                                          >
                                            {item.name}
                                          </Typography>
                                        )
                                      )}
                                    </React.Fragment>
                                  )}
                                </Grid>
                              </Grid>
                            </Box>
                          )}
                        </Typography>
                      </TableCell>
                      {!isMobileView(width) && (
                        <React.Fragment>
                          <TableCell
                            className={
                              index == 0
                                ? classes.TableCellFirstRow
                                : classes.TableCell
                            }
                          >
                            {item.node.departments && (
                              <Typography>
                                {item.node.departments.map((item, index) => (
                                  <span key={index}>{item.name}</span>
                                ))}
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell
                            className={
                              index == 0
                                ? classes.TableCellFirstRow
                                : classes.TableCell
                            }
                          >
                            <Typography>{item.node.location.name}</Typography>
                          </TableCell>
                        </React.Fragment>
                      )}
                      <TableCell
                        align="right"
                        className={
                          index == 0
                            ? classes.ActionCellFirstRow
                            : classes.ActionCell
                        }
                      >
                        <BtnSml link={getJobPostPathname(item.node)} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    {list.length > rowsPerPage && (
                      <TablePagination
                        rowsPerPageOptions={[
                          5,
                          10,
                          25,
                          { label: 'All', value: -1 },
                        ]}
                        colSpan={4}
                        count={list.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' },
                          native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                        classes={{
                          root: classes.paginationRoot,
                          toolbar: classes.paginationToolbar,
                          spacer: classes.paginationSpacer,
                          caption: classes.paginationCaption,
                          input: classes.paginationInput,
                        }}
                      />
                    )}
                  </TableRow>
                </TableFooter>
              </Table>
            </Grid>
          )}
          {tableLite && (
            <Box component={Grid} item xs={12} mt={5}>
              <Box display="flex" justifyContent="center">
                <BtnLg
                  color="#044D29"
                  hoverColor="#044D29"
                  link="opportunities"
                  content="Find a job"
                />
              </Box>
            </Box>
          )}
          {(departmentTitle || showMoreButton) && (
            <Box component={Grid} item xs={12} mt={5}>
              <Box display="flex" justifyContent="center">
                <BtnLg
                  color="#044D29"
                  hoverColor="#044D29"
                  link="opportunities"
                  content="See all jobs"
                />
              </Box>
            </Box>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

JobListing.propTypes = {
  data: PropTypes.object.isRequired,
  width: PropTypes.string,
  location: PropTypes.object,
  tableLite: PropTypes.bool,
  ghId: PropTypes.number,
  latestJobs: PropTypes.bool,
  jobData: PropTypes.object,
  filtersOff: PropTypes.bool,
  departmentTitle: PropTypes.string,
  showMoreButton: PropTypes.bool,
  departments: PropTypes.bool,
};

export default compose(withWidth())(JobListing);
