import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Link from 'gatsby-link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  text: {
    border: '0px solid',
    borderRadius: '5px',
    padding: '10px 20px',
    backgroundColor: props =>
      props.color ? props.color : theme.palette.background.blue,
    color: theme.palette.text.secondary,
    transition: 'all 0.4s ease-in-out',
    '&:hover': {
      backgroundColor: props =>
        props.hoverColor ? props.hoverColor : theme.palette.background.dgreen,
      boxShadow: '0px 4px 8px 2px rgba(0,0,0,0.1)',
    },
  },
});

function BtnLg(props) {
  const { classes, link, content } = props;
  return (
    <Box display="flex">
      <Box display="flex" alignItems="center">
        <Link to={`/${link}`} exact={'true'} className={classes.text}>
          <Typography
            component="span"
            variant="body1"
            style={{ fontWeight: '800' }}
          >
            {content}
          </Typography>
        </Link>
      </Box>
    </Box>
  );
}

BtnLg.propTypes = {
  link: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  classes: PropTypes.object,
};

export default withStyles(styles)(BtnLg);
