import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { withStyles } from '@material-ui/styles';

const styles = ({ palette }) => ({
  root: {
    backgroundColor: palette.background.green,
    transition: 'all 0.2s ease-in-out',
    height: '40px',
    width: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px',
    float: 'right',
    '&:hover': {
      backgroundColor: palette.background.blue,
      '& svg': {
        color: palette.text.secondary,
      },
    },
  },
  icon: {
    color: palette.text.secondary,
    fontSize: '2rem',
  },
});

function BtnSml(props) {
  const { classes, link } = props;
  return (
    <Link to={`/${link}`} exact={'true'} className={classes.root}>
      <FontAwesomeIcon icon={faCaretRight} className={classes.icon} />
    </Link>
  );
}

BtnSml.propTypes = {
  link: PropTypes.string.isRequired,
  classes: PropTypes.object,
};

export default withStyles(styles)(BtnSml);
